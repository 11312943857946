import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css'; // Importuj plik CSS

const images = [
  { src: '/Images/image1.jpg', alt: 'Image 1', text: 'Brałeś leki?' },
  { src: '/Images/image2.jpg', alt: 'Image 2', text: 'Buk' },
  { src: '/Images/image3.jpg', alt: 'Image 3', text: 'Pet spod sklepu' },
  { src: '/Images/image4.jpg', alt: 'Image 4', text: 'Bombowy' },
  { src: '/Images/image5.jpg', alt: 'Image 5', text: 'Dbający o WSPÓLNE dobro' },
  { src: '/Images/image6.jpg', alt: 'Image 6', text: 'Czekający na Ciebie!' },
  { src: '/Images/image7.jpg', alt: 'Image 7', text: 'Piątkowy' },
  { src: '/Images/image8.jpg', alt: 'Image 8', text: 'Umęczony' },
  { src: '/Images/image9.jpg', alt: 'Image 9', text: 'Poniedziałkowy' },
  { src: '/Images/image10.jpg', alt: 'Image 10', text: 'Pewny siebie' },
  { src: '/Images/image11.jpg', alt: 'Image 11', text: 'Nielubiący zbędnego hałasu' },
  { src: '/Images/image12.jpg', alt: 'Image 12', text: 'Artysta' },
  { src: '/Images/image13.jpg', alt: 'Image 13', text: 'Zaskoczony' },
  { src: '/Images/image14.jpg', alt: 'Image 14', text: 'Mściwy' },
  { src: '/Images/image15.jpg', alt: 'Image 15', text: 'Uczuciowy' },
  { src: '/Images/image16.jpg', alt: 'Image 16', text: 'Towarzyski' },
  { src: '/Images/image17.jpg', alt: 'Image 17', text: 'Zdziwiony' },
  { src: '/Images/image18.jpg', alt: 'Image 18', text: 'Po długiej bolesnej przygodzie' },
  { src: '/Images/image19.jpg', alt: 'Image 19', text: 'Lekko głodnawy' },
  { src: '/Images/image20.jpg', alt: 'Image 20', text: 'Zarobiony' },
  { src: '/Images/image21.jpg', alt: 'Image 21', text: 'Niewyraźny' },
  { src: '/Images/image22.jpg', alt: 'Image 22', text: 'Poimprezowy' },
  { src: '/Images/image23.jpg', alt: 'Image 23', text: 'Bohaterski' },
  { src: '/Images/image24.jpg', alt: 'Image 24', text: 'Piracki' },
  { src: '/Images/image25.jpg', alt: 'Image 25', text: 'Boski' },
  { src: '/Images/image26.jpg', alt: 'Image 26', text: 'Oświecony' },
  { src: '/Images/image27.jpg', alt: 'Image 27', text: 'Amigo' },
  { src: '/Images/image28.jpg', alt: 'Image 28', text: 'Sam pośrodku świata' },
  { src: '/Images/image29.jpg', alt: 'Image 29', text: 'Kochający' },
  { src: '/Images/image30.jpg', alt: 'Image 30', text: 'Odprężony' },
  { src: '/Images/image31.jpg', alt: 'Image 31', text: 'Nadpobudliwy' },
  { src: '/Images/image32.jpg', alt: 'Image 32', text: 'Wolny' },
  { src: '/Images/image33.jpg', alt: 'Image 33', text: 'Poważny' },
  { src: '/Images/image34.jpg', alt: 'Image 34', text: 'Kleptoman' },
  { src: '/Images/image35.jpg', alt: 'Image 35', text: 'Wypoczęty' },
  { src: '/Images/image36.jpg', alt: 'Image 36', text: 'Myśliciel' },
  { src: '/Images/image37.jpg', alt: 'Image 37', text: 'Ogarnięty' },
  { src: '/Images/image38.jpg', alt: 'Image 38', text: 'Pajac' },
  { src: '/Images/image39.jpg', alt: 'Image 39', text: 'Harpagan' },
  { src: '/Images/image40.jpg', alt: 'Image 40', text: 'Zachowujący spokój' },
  { src: '/Images/image41.jpg', alt: 'Image 41', text: 'Chillujący bombę' },
  { src: '/Images/image42.jpg', alt: 'Image 42', text: 'Triumfalny' },
  { src: '/Images/image43.jpg', alt: 'Image 43', text: 'Wyniesiony' },
  { src: '/Images/image44.jpg', alt: 'Image 44', text: 'Imprezowy (w trakcie)' },
  { src: '/Images/image45.jpg', alt: 'Image 45', text: 'Śpiący' },
  { src: '/Images/image46.jpg', alt: 'Image 46', text: 'Skondensowany' },
  { src: '/Images/image47.jpg', alt: 'Image 47', text: 'Pełen radości' },
  { src: '/Images/image48.jpg', alt: 'Image 48', text: 'Korposzczur' },
  { src: '/Images/image49.jpg', alt: 'Image 49', text: 'Przeuczony' },
  { src: '/Images/image50.jpg', alt: 'Image 50', text: 'Rozrywkowy' },
  // Dodaj więcej obrazków
];

const isTesting = false; // Zmienna do określenia trybu testowania

const DailyImage = () => {
  const [currentImage, setCurrentImage] = useState(null);
  const [lastDrawDate, setLastDrawDate] = useState(null);

  useEffect(() => {
    const lastDraw = localStorage.getItem('lastDrawDate');
    const savedImage = localStorage.getItem('savedImage');
    if (lastDraw && savedImage) {
      const lastDrawDate = new Date(lastDraw);
      setLastDrawDate(lastDrawDate);
      const today = new Date();
      if (lastDrawDate.toDateString() === today.toDateString()) {
        setCurrentImage(JSON.parse(savedImage));
      }
    }
  }, []);

  const drawImage = () => {
    const today = new Date();
    const todayStr = today.toDateString();
    
    if (!isTesting && lastDrawDate && lastDrawDate.toDateString() === todayStr) {
      alert('Już wiesz jakim Elmo dzisiaj jesteś! Przyjdź jutro');
      return;
    }

    const randomImage = images[Math.floor(Math.random() * images.length)];
    setCurrentImage(randomImage);
    localStorage.setItem('lastDrawDate', today);
    localStorage.setItem('savedImage', JSON.stringify(randomImage));
    setLastDrawDate(today);
  };

  const resetDraw = () => {
    localStorage.removeItem('lastDrawDate');
    localStorage.removeItem('savedImage');
    setLastDrawDate(null);
    setCurrentImage(null);
  };

  return (
    <div>
      <h1>Jakim Elmo dzisiaj jesteś?</h1>
      <button className="large-button" onClick={drawImage}>Sprawdzam!</button>
      <p></p><p></p>
      {currentImage && (
        <div>
          <img src={currentImage.src} alt={currentImage.alt} />
          <h2>{currentImage.text}</h2>
        </div>
      )}
    </div>
  );
};

export default DailyImage;
